import { http } from './config'
import authHeader from './auth-header';

export default {


    

    totais_vendas_campanha: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-venda-campanha/', {
           params: params,
           headers: authHeader()   
        })
    },


    total_sinistro: (dataI, dataF, nomeEmpresa, tipo) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-sinistro/', {
           params: params,
           headers: authHeader()   
        })
    },



    lista_perda_cancelamento: (dataI, dataF, nomeEmpresa, tipo) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            //'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/lista-perda-cancelamento/', {
           params: params,
           headers: authHeader()   
        })
    },


    

    total_contrato_inadimplencia_periodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const  params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao
        }
        return http.get('/campanha-dash/total-contrato-inadimplencia-periodo/', {
            params: params,
            headers: authHeader()
        })        
    },
   
    total_contratos_perda: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const  params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao
        }
        return http.get('/campanha-dash/total-contrato-perda-cancelamento/', {
            params: params,
            headers: authHeader()
        })        
    },
    
    total_inadimplencia_periodo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const  params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao
        }
        return http.get('/campanha-dash/total-inadimplencia-periodo/', {
            params: params,
            headers: authHeader()
        })        
    },

    total_perda_cancelamento: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao
        }
        return http.get('/campanha-dash/total-perda-cancelamento/', {
            params: params,
            headers: authHeader() 
        })
    },


    totais_esperado_reneg: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            //'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-esperado-reneg/', {
           params: params,
           headers: authHeader()   
        })
    },

    totais_recebido_reneg: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            //'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-recebido-reneg/', {
           params: params,
           headers: authHeader()   
        })
    },


    



    total_venda_campanha_grafico: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-venda-campanha-grafico/', {
           params: params,
           headers: authHeader()   
        })
    },

    total_venda_campanha_empresa_grafico: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/total-venda-campanha-empresa-grafico/', {
           params: params,
           headers: authHeader()   
        })
    },

    lista_venda_campanha: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/lista-venda-campanha', {
           params: params,
           headers: authHeader()   
        })
    },
    lista_venda_campanha_incoforme: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro
            //'opcao': opcao
        }
        return http.get('/campanha-dash/lista-venda-campanha-incoforme', {
           params: params,
           headers: authHeader()   
        })
    },

    lista_campanha_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
           // 'filtro': filtro,
            'campanha': opcao
          };

       
          return http.get('/planilha-dash/lista-contrato-campanha-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    lista_campanha_incoforme_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
           // 'filtro': filtro,
            'campanha': opcao
          };

       
          return http.get('/planilha-dash/lista-contrato-campanha-incoforme-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },




    


    
    
    

   

}
